<template>
  <div>
    <b-container fluid="lg">
      <v-card>
        <v-card-text>
          <b-row style="margin-bottom:0px">
            <b-col lg="10" md="10" sm="12" xs="12" cols="12" class="block-btns">
              <v-checkbox
                class="available365"
                v-model="available365"
                :label="$t('CALENDAR.AVAILABLE')"
                @change="available()"
              ></v-checkbox>
              <v-btn
                color="primary"
                dark
                @click.stop="open_dialog()"
                v-if="available365 !== true"
                >{{ $t("CALENDAR.EDIT_HOLIDAYS") }}
              </v-btn>
              <v-btn
                color="success"
                dark
                @click.stop="open_dialog_course()"
                style="margin-left: 10px"
                v-if="isCourseCompany"
                >{{ $t("CALENDAR.EDIT_COURSE_START_DATES") }}
              </v-btn>
              <v-btn
                color="warning"
                dark
                @click.stop="open_dialog_room()"
                style="margin-left: 10px"
                >{{ $t("CALENDAR.EDIT_ROOM_START_DATES") }}
              </v-btn>
            </b-col>
            <b-col
              lg="2"
              md="2"
              sm="12"
              xs="12"
              cols="12"
              style="margin-top: -10px"
            >
              <div class="d-flex justify-content-end">
                <v-switch
                  style="width: inherit"
                  v-model="yearly"
                  :label="
                    yearly
                      ? ' ' + $t('CALENDAR.YEAR')
                      : ' ' + $t('CALENDAR.MONTH')
                  "
                ></v-switch>
              </div>
            </b-col>
          </b-row>
        </v-card-text>
      </v-card>

      <div style="text-align: right; display:inherit; ">
        <div style="width: 100px;"></div>
      </div>
      <b-row>
        <b-col>
          <vc-calendar
            locale="en"
            :first-day-of-week="calendar_format"
            :columns="layout.columns"
            :rows="layout.rows"
            is-expanded
            :attributes="calendar_dates"
            v-model="dates"
          >
          </vc-calendar>
        </b-col>
      </b-row>
    </b-container>

    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("CALENDAR.EDIT_HOLIDAYS") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div v-for="(holiday, index) in holidays_modified" :key="index">
              <RowHoliday
                :index="index"
                :holiday="holiday"
                v-on:deleteItem="deleteItem(index)"
                :currentdates="holidays_modified"
                ref="row_holiday"
              >
              </RowHoliday>
            </div>
            <v-btn color="primary" dark @click.stop="add_holiday()"
              >{{ $t("CALENDAR.ADD_HOLIDAY") }}
            </v-btn>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="grey"
            class="float-right d-inline-block mr-3"
            @click="dialog = false"
            >{{ $t("GENERAL.CLOSE") }}</v-btn
          >
          <v-btn color="primary" dark @click="save_holidays()"
            >{{ $t("GENERAL.SAVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_course_pattern" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("CALENDAR.EDIT_COURSE_START_DATES")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <start-date-template
              :startDateTemplate="course_pattern_modified"
              v-on:save_start_date_template="save_course_start_dates($event)"
              ref="start_date_template_course"
            ></start-date-template>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="grey"
            class="float-right d-inline-block mr-3"
            @click="dialog_course_pattern = false"
            >{{ $t("GENERAL.CLOSE") }}</v-btn
          >
          <v-btn color="primary" dark @click="save_course_pattern()"
            >{{ $t("GENERAL.SAVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_room_pattern" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("CALENDAR.EDIT_ROOM_START_DATES")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <start-date-template
              :startDateTemplate="room_pattern_modified"
              v-on:save_start_date_template="save_room_start_dates($event)"
              ref="start_date_template_room"
            ></start-date-template>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="grey"
            class="float-right d-inline-block mr-3"
            @click="dialog_room_pattern = false"
            >{{ $t("GENERAL.CLOSE") }}</v-btn
          >
          <v-btn color="primary" dark @click="save_room_pattern()"
            >{{ $t("GENERAL.SAVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import {
  UPDATE_CAMPUS_AVAILABILITY,
  UPDATE_CAMPUS_CALENDAR_STARTDATE,
} from "@/core/services/store/general/campus.module";
import { STORE_PRODUCT_START_DATE_TEMPLATE } from "@/core/services/store/general/product_start_date_template.module";
import { UPDATE_PRODUCT_START_DATE_TEMPLATE } from "@/core/services/store/general/product_start_date_template.module";
import { GET_ALL_PRODUCT_START_DATE_TEMPLATES } from "@/core/services/store/general/product_start_date_template.module";
import { UPDATE_HOLIDAYS } from "@/core/services/store/general/campus.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RowHoliday from "@/view/content/components/forms/RowHoliday";
import StartDateTemplate from "@/view/content/components/forms/StartDateTemplate";
import { GET_CAMPUS } from "@/core/services/store/general/campus_page.module";
//import VueMoment from "vue-moment";
import { mapGetters } from "vuex";
import Bugsnag from "@bugsnag/js";

const COURSE_COMPANY = 1;
const COMPANY_TYPE_LODGING = 2;
const COMPANY_TYPE_HOMESTAY = 3;

export default {
  name: "calendar",
  data: () => ({
    yearly: true,
    dialog: false,
    dialog_course_pattern: false,
    dialog_room_pattern: false,
    dialog2: false,
    files: [],
    file: "",
    holidays: [],
    holidays_modified: [],
    dates: [],
    course_pattern: {},
    room_pattern: {},
    course_pattern_modified: {},
    room_pattern_modified: {},
    available365: false,
  }),
  components: {
    RowHoliday,
    StartDateTemplate,
  },
  computed: {
    calendar_format() {
      if (this.campus.calendar_format === 1) return 2;
      else return 1;
    },
    isCourseCompany() {
      return this.company.companyTypeId === COURSE_COMPANY;
    },
    layout() {
      if (this.yearly) {
        return this.$screens({
          // Default layout for mobile
          default: {
            columns: 1,
            rows: 1,
            isExpanded: true,
          },
          xxl: {
            columns: 5,
            rows: 2,
            isExpanded: true,
          },
          xl: {
            columns: 4,
            rows: 2,
            isExpanded: true,
          },
          lg: {
            columns: 3,
            rows: 2,
            isExpanded: true,
          },
          mdd: {
            columns: 2,
            rows: 2,
            isExpanded: true,
          },
          md: {
            columns: 3,
            rows: 2,
            isExpanded: true,
          },
          sm: {
            columns: 2,
            rows: 2,
            isExpanded: true,
          },
          xs: {
            columns: 1,
            rows: 2,
            isExpanded: true,
          },
        });
      } else {
        return this.$screens({
          // Default layout for mobile
          default: {
            columns: 1,
            rows: 1,
            isExpanded: true,
          },
        });
      }
    },
    ...mapGetters(["campus", "productStartDateTemplates", "company"]),
    calendar_dates() {
      var daysOfWeek = [];
      var daysOfMonth = [];
      var manual_dates = [];

      var course_dates = [];
      if (typeof this.course_pattern !== "undefined") {
        if (this.course_pattern.isPattern === 0) {
          this.course_pattern.product_start_date_template_manual_dates.forEach(
            manual => {
              manual_dates.push(
                new Date(
                  this.$moment(manual.startDate)
                    .add(12, "hours")
                    .format("YYYY-MM-DD")
                )
              );
            }
          );
          course_dates.push({
            bar: "green",
            dates: manual_dates,
            popover: {
              label: this.$t("CALENDAR.COURSE_START_DATE"),
            },
          });
        } else {
          if (this.course_pattern.isDaysOfWeek === 1) {
            try {
              daysOfWeek = JSON.parse(this.course_pattern.daysOfWeek);
              course_dates.push({
                bar: "green",
                dates: {
                  start: new Date("2000-01-01"),
                  weekdays: daysOfWeek.map(day => {
                    day = day + 1;
                    if (day === 8) day = 1;
                    return day;
                  }),
                },
                popover: {
                  label: this.$t("CALENDAR.COURSE_START_DATE"),
                },
              });
            } catch (e) {
              daysOfWeek = [];
            }
          } else {
            try {
              daysOfMonth = JSON.parse(this.course_pattern.daysOfMonth);
              course_dates.push({
                bar: "green",
                dates: {
                  start: new Date("2000-01-01"),
                  days: daysOfMonth,
                },
                popover: {
                  label: this.$t("CALENDAR.COURSE_START_DATE"),
                },
              });
            } catch (e) {
              daysOfMonth = [];
            }
          }
        }
      }

      var room_dates = [];
      if (typeof this.room_pattern !== "undefined") {
        if (this.room_pattern.isPattern === 0) {
          manual_dates = [];
          this.room_pattern.product_start_date_template_manual_dates.forEach(
            manual => {
              manual_dates.push(
                new Date(
                  this.$moment(manual.startDate)
                    .add(12, "hours")
                    .format("YYYY-MM-DD")
                )
              );
            }
          );
          room_dates.push({
            bar: "orange",
            dates: manual_dates,
            popover: {
              label: this.$t("CALENDAR.ROOM_START_DATE"),
            },
          });
        } else {
          if (this.room_pattern.isDaysOfWeek === 1) {
            try {
              daysOfWeek = JSON.parse(this.room_pattern.daysOfWeek);
              room_dates.push({
                bar: "orange",
                dates: {
                  start: new Date("2000-01-01"),
                  weekdays: daysOfWeek.map(day => {
                    day = day + 1;
                    if (day === 8) day = 1;
                    return day;
                  }),
                },
                popover: {
                  label: this.$t("CALENDAR.ROOM_START_DATE"),
                },
              });
            } catch (e) {
              daysOfWeek = [];
            }
          } else {
            try {
              daysOfMonth = JSON.parse(this.room_pattern.daysOfMonth);
              room_dates.push({
                bar: "orange",
                dates: {
                  start: new Date("2000-01-01"),
                  days: daysOfMonth,
                },
                popover: {
                  label: this.$t("CALENDAR.ROOM_START_DATE"),
                },
              });
            } catch (e) {
              daysOfMonth = [];
            }
          }
        }
      }

      var holiday_dates = [];
      if (!this.available365) {
        this.holidays.forEach(holi => {
          if (holi.isRepeat === 1) {
            var repetition = {};
            repetition = {
              monthlyInterval: 12,
              days: new Date(
                this.$moment(holi.startDate)
                  .add(12, "hours")
                  .format("YYYY-MM-DD")
              ).getDate(),
              start: new Date(
                this.$moment(holi.startDate)
                  .add(12, "hours")
                  .format("YYYY-MM-DD")
              ),
            };
            holiday_dates.push({
              highlight: {
                color: "blue",
                fillMode: "light",
              },
              popover: {
                label: holi.name,
              },
              dates: [
                {
                  start: new Date(
                    this.$moment(holi.startDate).format("YYYY-MM-DD")
                  ),
                  ...repetition,
                },
              ],
            });
          } else {
            holiday_dates.push({
              highlight: {
                color: "blue",
                fillMode: "light",
              },
              popover: {
                label: holi.name,
              },
              dates: [holi.startDate],
              fillMode: "none",
            });
          }
        });
      }
      var final_dates = [...course_dates, ...room_dates, ...holiday_dates];

      Bugsnag.notify(new Error(this.$t("GENERAL.BAD_BUT_NOT_FATAL")), function(
        event
      ) {
        event.severity = "info";
        event.addMetadata("final_dates", final_dates);
      });
      return final_dates;
    },
  },
  async mounted() {
    this.$store.dispatch(GET_CAMPUS, this.campus.campusId);
    await this.$store.dispatch(GET_ALL_PRODUCT_START_DATE_TEMPLATES, {
      campusId: this.campus.campusId,
    });
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("CALENDAR.CALENDAR") },
    ]);
    this.holidays = Vue.util.extend([], this.campus.campus_holidays);
    //Before charge this startDateTemplate we have to check if exist specific template in the campus. If not, we search the first template existing. If not, we create a new empty template
    var course_patterns = [];
    if (this.campus.courseStartDateTemplateId !== null) {
      course_patterns = this.productStartDateTemplates.filter(start_date => {
        if (
          this.campus.courseStartDateTemplateId ===
          start_date.productStartDateTemplateId
        )
          return true;
        return false;
      });
      if (course_patterns.length > 0) {
        this.course_pattern = Vue.util.extend({}, course_patterns[0]);
      }
    }
    //In the case of company type lodging or homestay, it's not necessary to show course patterns in calendar.
    if (
      this.isEmpty(this.course_pattern) ||
      this.company.companyTypeId === COMPANY_TYPE_LODGING ||
      this.company.companyTypeId === COMPANY_TYPE_HOMESTAY
    ) {
      this.course_pattern = {
        productStartDateTemplateId: 0,
        campusId: this.campus.campusId,
        productTypeId: 1,
        isReady: 1,
        isPattern: null,
        isDaysOfWeek: null,
        repeatDurationTypeId: 1,
        repeatDurationAmount: 0,
        validFrom: new Date(),
        daysOfWeek: "",
        daysOfMonth: "",
        product_start_date_template_manual_dates: [],
      };
    }
    //The same with rooms
    var room_patterns = [];
    if (this.campus.roomStartDateTemplateId !== null) {
      room_patterns = this.productStartDateTemplates.filter(start_date => {
        if (
          this.campus.roomStartDateTemplateId ===
          start_date.productStartDateTemplateId
        )
          return true;
        return false;
      });
      if (room_patterns.length > 0) {
        this.room_pattern = Vue.util.extend({}, room_patterns[0]);
      }
    }
    if (this.isEmpty(this.room_pattern)) {
      this.room_pattern = {
        productStartDateTemplateId: 0,
        campusId: this.campus.campusId,
        productTypeId: 2,
        isReady: 1,
        isPattern: null,
        isDaysOfWeek: null,
        repeatDurationTypeId: 1,
        repeatDurationAmount: 0,
        validFrom: new Date(),
        daysOfWeek: "",
        daysOfMonth: "",
      };
    }
    this.available365 = this.campus.available365;
  },
  created() {},
  methods: {
    available() {
      this.$store.commit("setIsVisibleLoader", true);
      this.$store
        .dispatch(UPDATE_CAMPUS_AVAILABILITY, {
          campusId: this.campus.campusId,
          available365: this.available365,
        })
        .then(data => {
          data;
          this.$store.commit("setIsVisibleLoader", false);
          this.campus.available365 = this.available365;
          this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        });
    },
    save_course_start_dates(data) {
      this.course_pattern_modified = data;
    },
    save_room_start_dates(data) {
      this.room_pattern_modified = data;
    },
    async save_course_pattern() {
      if (await this.$refs.start_date_template_course.validate()) {
        await this.save_start_date_template(
          { ...this.course_pattern_modified, is_course_pattern: true },
          "course"
        );
        this.$store
          .dispatch(UPDATE_CAMPUS_CALENDAR_STARTDATE, {
            campusId: this.campus.campusId,
            courseStartDateTemplateId: this.course_pattern_modified
              .productStartDateTemplateId,
          })
          .then(() => {
            this.campus.productStartDateTemplateId = this.course_pattern_modified.productStartDateTemplateId;
          });
      } else {
        this.$bvToast.toast(this.$t("GENERAL.PLEASE_VERIFY_FIELDS"), {
          title: this.$t("GENERAL.ERROR"),
          variant: "danger",
          solid: true,
        });
      }
    },
    async save_room_pattern() {
      if (await this.$refs.start_date_template_room.validate()) {
        await this.save_start_date_template(
          { ...this.room_pattern_modified, is_room_pattern: true },
          "room"
        );
        this.$store
          .dispatch(UPDATE_CAMPUS_CALENDAR_STARTDATE, {
            campusId: this.campus.campusId,
            roomStartDateTemplateId: this.room_pattern_modified
              .productStartDateTemplateId,
          })
          .then(() => {
            this.campus.roomStartDateTemplateId = this.room_pattern_modified.productStartDateTemplateId;
          });
      } else {
        this.$bvToast.toast(this.$t("GENERAL.PLEASE_VERIFY_FIELDS"), {
          title: this.$t("GENERAL.ERROR"),
          variant: "danger",
          solid: true,
        });
      }
    },
    async save_start_date_template(data, courseOrRoom) {
      if (
        data.productStartDateTemplateId === 0 ||
        data.productStartDateTemplateId === ""
      ) {
        await this.$store
          .dispatch(
            STORE_PRODUCT_START_DATE_TEMPLATE,
            Vue.util.extend({}, data)
          )
          .then(result => {
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
            if (courseOrRoom === "course") {
              this.dialog_course_pattern = false;
              this.course_pattern = result.productStartDateTemplate;
              this.course_pattern_modified = result.productStartDateTemplate;
            }
            if (courseOrRoom === "room") {
              this.dialog_room_pattern = false;
              this.room_pattern = result.productStartDateTemplate;
              this.room_pattern_modified = result.productStartDateTemplate;
            }
          })
          .catch(error => {
            if (typeof error.response !== "undefined") {
              if (typeof error.response.data.errors !== "undefined") {
                for (let field of Object.keys(error.response.data.errors)) {
                  this.$bvToast.toast(error.response.data.errors[field][0], {
                    title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
                    variant: "danger",
                    solid: true,
                  });
                }
              } else {
                if (typeof error.response.data !== "undefined") {
                  this.$bvToast.toast(error.response.data.message, {
                    title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
                    variant: "danger",
                    solid: true,
                  });
                }
              }
            }
          });
      } else {
        await this.$store
          .dispatch(
            UPDATE_PRODUCT_START_DATE_TEMPLATE,
            Vue.util.extend({}, data)
          )
          .then(result => {
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
            if (courseOrRoom === "course") {
              this.course_pattern = result.data.productStartDateTemplate;
              this.course_pattern_modified =
                result.data.productStartDateTemplate;
              this.dialog_course_pattern = false;
            }
            if (courseOrRoom === "room") {
              this.room_pattern = result.data.productStartDateTemplate;
              this.room_pattern_modified = result.data.productStartDateTemplate;
              this.dialog_room_pattern = false;
            }
          });
      }
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      }
      return JSON.stringify(obj) === JSON.stringify({});
    },
    deleteItem(index) {
      this.holidays_modified.splice(index, 1);
    },
    open_dialog() {
      this.dialog = true;
      this.holidays_modified = Vue.util.extend([], this.holidays);
    },
    open_dialog_course() {
      this.dialog_course_pattern = true;
      this.course_pattern_modified = Vue.util.extend({}, this.course_pattern);
    },
    open_dialog_room() {
      this.dialog_room_pattern = true;
      this.room_pattern_modified = Vue.util.extend({}, this.room_pattern);
    },
    async save_holidays() {
      let valid_fields = true;
      for (const row of this.$refs.row_holiday) {
        await row.$refs.observer.validate().then(data => {
          if (!data) {
            valid_fields = false;
          }
        });
      }

      if (valid_fields) {
        this.$store
          .dispatch(UPDATE_HOLIDAYS, {
            campusId: this.campus.campusId,
            data: this.holidays_modified,
          })
          .then(result => {
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
            this.dialog = false;
            this.holidays = Vue.util.extend([], result.campus_holidays);
          })
          .catch(error => {
            if (typeof error.response !== "undefined") {
              if (typeof error.response.data.errors !== "undefined") {
                for (let field of Object.keys(error.response.data.errors)) {
                  this.$bvToast.toast(error.response.data.errors[field][0], {
                    title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
                    variant: "danger",
                    solid: true,
                  });
                }
              } else {
                if (typeof error.response.data !== "undefined") {
                  this.$bvToast.toast(error.response.data.message, {
                    title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
                    variant: "danger",
                    solid: true,
                  });
                }
              }
            }
          });
      } else {
        this.$bvToast.toast(this.$t("GENERAL.PLEASE_VERIFY_FIELDS"), {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    add_holiday() {
      this.holidays_modified.push({
        campusHolidayId: 0,
        campusId: this.campus.campusId,
        name: "",
        startDate: "",
        isRepeat: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__container {
  display: unset;
}
</style>
