<template>
  <div class="row-holiday">
    <b-row>
      <b-col lg="12" md="12" sm="12">
        <validation-observer ref="observer">
          <v-form ref="form" style="margin-bottom: -10px">
            <b-row style="margin-bottom: 0px;">
              <b-col lg="6" md="6" sm="6" cols="11">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('CALENDAR.HOLIDAY_NAME')"
                  rules="required"
                >
                  <v-text-field
                    class="fields_modified required"
                    :label="$t('CALENDAR.HOLIDAY_NAME')"
                    outlined
                    dense
                    v-model="holiday.name"
                    hide-details="auto"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </b-col>
              <b-col lg="3" md="5" sm="5" cols="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="holiday.startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  style="display: unset;"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('CALENDAR.HOLIDAY_DATE')"
                      rules="required"
                    >
                      <v-text-field
                        class="fields_modified required"
                        outlined
                        dense
                        v-model="holiday.startDate"
                        :label="$t('CALENDAR.SELECT_DATE')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-model="holiday.startDate"
                    no-title
                    scrollable
                    :allowedDates="val => currentHolidays.indexOf(val) === -1"
                    @input="
                      menu = false;
                      $refs.menu.save(holiday.startDate);
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </b-col>
              <b-col lg="1" md="1" sm="1" cols="1">
                <a
                  class="btn btn-icon btn-light btn-hover-danger"
                  @click="deleteItem()"
                >
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <v-icon color="red">
                      mdi-delete
                    </v-icon>
                  </span>
                </a>
              </b-col>
            </b-row>
            <b-row style="margin-bottom: 0px;">
              <b-col lg="4" md="4" sm="6" cols="12">
                <v-checkbox
                  style="margin-top: 0px"
                  v-model="holiday.isRepeat"
                  :label="$t('CALENDAR.REPEAT_ANNUALLY')"
                ></v-checkbox>
              </b-col>
            </b-row>
          </v-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  name: "row-holiday",
  data() {
    return {
      errors: [],
      menu: false,
      data: {},
    };
  },
  props: {
    index: Number,
    holiday: Object,
    currentdates: Array,
  },
  methods: {
    deleteItem() {
      this.$emit("deleteItem", this.index);
    },
  },
  computed: {
    currentHolidays() {
      let currentDates = [];
      this.currentdates.forEach(startDate => {
        if (startDate.isRepeat === 1) {
          currentDates.push(startDate.startDate);
          currentDates.push(
            this.$moment(startDate.startDate)
              .add(1, "year")
              .format("YYYY-MM-DD")
          );
          currentDates.push(
            this.$moment(startDate.startDate)
              .add(2, "year")
              .format("YYYY-MM-DD")
          );
          currentDates.push(
            this.$moment(startDate.startDate)
              .add(3, "year")
              .format("YYYY-MM-DD")
          );
          currentDates.push(
            this.$moment(startDate.startDate)
              .add(4, "year")
              .format("YYYY-MM-DD")
          );
          currentDates.push(
            this.$moment(startDate.startDate)
              .add(5, "year")
              .format("YYYY-MM-DD")
          );
        } else {
          currentDates.push(startDate.startDate);
        }
      });
      return currentDates;
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    var holiday = Vue.util.extend({}, this.holiday);
    holiday.isRepeat = !holiday.isRepeat;
    this.data = holiday;
  },
};
</script>

<style lang="scss" scoped>
.fields_modified {
  margin-top: 0px;
  .v-input__control {
    .v-messages {
      height: 0px;
      min-height: 0px;
    }
  }
}
.row > div {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 5px;
}
</style>
